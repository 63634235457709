import { Input } from '@material-tailwind/react';
import React from 'react';

interface InlineTextInputProps {
  value: string;
  onChange: (newValue: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
  containerClassName?: string;
  labelClassName?: string;
  placeholder?: string;
}

const InlineTextInput: React.FC<InlineTextInputProps> = ({
  value,
  onChange,
  onKeyDown,
  className,
  containerClassName,
  labelClassName,
  placeholder,
}) => {
  const inputClasses = `
    h-9 !border-gray-300 focus:!border-blue-500 focus:!border 
    rounded-l rounded-r
    ${className}
  `;
  const labelClasses = `
    !hidden before:!hidden after:!hidden
    ${labelClassName}
  `;
  return (
    <Input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={onKeyDown}
      className={inputClasses}
      labelProps={{ className: labelClasses }}
      containerProps={{
        className: `h-auto ${containerClassName}`,
      }}
      crossOrigin={undefined}
    />
  );
};

export default InlineTextInput;
