import React from 'react';
import { Card, CardBody } from '@material-tailwind/react';
import ViewHeader from '../view-header';
import MessageLoading from '../loading/message-loading';
import InfoError from '../info-error';

type ViewContainerProps = {
  title: string;
  subtitle?: string;
  headerElement?: React.ReactNode;
  children: React.ReactNode;
  loading?: boolean;
  error?: Error | null;
};

const ViewContainer: React.FC<ViewContainerProps> = ({
  title,
  subtitle,
  headerElement,
  children,
  loading,
  error,
}) => {
  return (
    <Card className="h-screen w-full">
      <ViewHeader title={title} subtitle={subtitle}>
        {headerElement}
      </ViewHeader>
      <CardBody className="overflow-auto grow p-2 pb-14 w-full min-w-0">
        {loading && <MessageLoading />}
        {error && <InfoError error={error} />}
        {!loading && !error && children}
      </CardBody>
    </Card>
  );
};

export default ViewContainer;
