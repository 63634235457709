import { IconButton } from '@material-tailwind/react';
import Icons from 'components/atoms/icons';

interface WhatsappButtonProps {
  phone: string;
  name?: string;
  company?: string;
  customMessage?: string;
}

const WHATSAPP_URL = 'https://web.whatsapp.com/send';

const WhatsappButton: React.FC<WhatsappButtonProps> = ({
  phone,
  name,
  company,
  customMessage,
}) => {
  let message = '';
  if (customMessage) message = customMessage;
  // TODO: check when implementing i18n
  else if (name && company) message = `Hola, soy ${name} de ${company}`;

  const url = new URL(WHATSAPP_URL);
  url.searchParams.append('phone', phone);
  url.searchParams.append('text', message);

  return (
    <a
      href={url.toString()}
      target="_blank"
      rel="noopener noreferrer"
      className="inline-block mx-1"
    >
      <IconButton onClick={() => {}} variant="text" className="w-7 h-7">
        <Icons.Whatsapp />
      </IconButton>
    </a>
  );
};

export default WhatsappButton;
