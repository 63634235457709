// src/hooks/calls.ts

import {
  ApiQueryParams,
  CallWithAnalysisAndUser,
  User,
} from 'src/api/generated';
import { FilterParam, generateFilterQueryString } from 'src/utils/queryParams';
import { useSearch } from './search';
import { useFilterAgent } from './users';
import { SortOrder } from 'components/molecules/tables/sorting-header';
import { useDataFilteringQueryParams } from './dataFiltering';
import { useMemo } from 'react';
import { useCallLogs } from './api/calls/call-logs';
import { useContacts } from './api/contacts/contacts';

export const useCallsQueryParams = (
  agentsInFilter: User[],
  currentUser: User | null,
  defaultSortField: string | null,
  defaultSortOrder: SortOrder,
  defaultPageSize: number | null
) => {
  const { selectedAgentId, sendAgentSelection } = useFilterAgent(
    agentsInFilter,
    currentUser
  );

  const filters: Record<string, FilterParam> = {};
  if (selectedAgentId) filters.userId = { value: selectedAgentId };

  const filterBy = generateFilterQueryString(filters);
  const { searchQueryParams, sendSearch } = useSearch();
  const {
    dataFilteringQueryParams,
    sortField,
    selectSortField,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    pageSize,
    setPageSize,
  } = useDataFilteringQueryParams(
    defaultSortField,
    defaultSortOrder,
    defaultPageSize
  );

  const queryParams: ApiQueryParams = {
    filterBy,
    ...searchQueryParams,
    ...dataFilteringQueryParams,
  };

  return {
    queryParams,
    selectedAgentId,
    sendAgentSelection,
    sendSearch,
    sortField,
    selectSortField,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    pageSize,
    setPageSize,
  };
};

export const useCallsCallLogs = (calls: CallWithAnalysisAndUser[]) => {
  const callsIds = useMemo(() => calls.map((call) => call.id), [calls]);

  const filters: Record<string, FilterParam> = {
    callId: { value: callsIds },
  };
  const filterBy = generateFilterQueryString(filters);

  return useCallLogs({ filterBy });
};

export const useCallsContacts = (calls: CallWithAnalysisAndUser[]) => {
  const callsContactsIds: number[] = useMemo(() => {
    const contactsIds = calls.map((call) => call.contactId);
    const uniqueContactsIds = [...new Set(contactsIds)];
    return uniqueContactsIds;
  }, [calls]);

  const filters: Record<string, FilterParam> = {
    id: { value: callsContactsIds },
  };
  const queryParams: ApiQueryParams = {
    filterBy: generateFilterQueryString(filters),
  };

  const contactsResponse = useContacts(queryParams);

  return {
    ...contactsResponse,
    queryParams,
  };
};
