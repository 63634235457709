import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@material-tailwind/react';
import CancellableTextInput from '../cancellable-text-input';

type SearchInputProps = {
  sendSearch: (value: string) => void;
  inputValue: string;
  onChange: (searchTerm: string) => void;
  placeholder?: string;
  buttonText?: string;
};

const SearchInput: React.FC<SearchInputProps> = ({
  sendSearch,
  inputValue,
  onChange,
  // TODO: check when implementing i18n
  placeholder = 'Buscar',
}) => {
  const clearInput = () => {
    onChange('');
    sendSearch('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      sendSearch(inputValue);
    }
  };

  return (
    <div className="flex max-w-[13rem]">
      <CancellableTextInput
        value={inputValue}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        onClear={clearInput}
        placeholder={placeholder}
        className="!rounded-r-none"
      />
      <IconButton
        onClick={() => sendSearch(inputValue)}
        className="h-9 bg-blue-600 rounded rounded-l-none"
        ripple={true}
      >
        <MagnifyingGlassIcon className="w-4 mr-1" />
      </IconButton>
    </div>
  );
};

export default SearchInput;
