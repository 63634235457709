import React from 'react';
import { useAgents } from 'hooks/api/users';
import ViewContainer from 'components/atoms/view-container';
import AdvancedTable from 'components/molecules/tables/advanced-table';
import { TableRowProps } from 'components/molecules/tables/table';
import { User } from 'src/api/generated';
import MessageLoading from 'components/atoms/loading/message-loading';
import InfoError from 'components/atoms/info-error';
import UserPhoneInput from 'components/molecules/forms/editable/users/user-phone-input';

const UsersView: React.FC = () => {
  const { users, loading: usersLoading, error: usersError } = useAgents();

  const columns = [
    {
      header: 'Nombre',
      accessor: 'fullName',
    },
    {
      header: 'Teléfono Individual',
      accessor: 'phoneNumber',
    },
  ];

  const userPhoneNumberElement = (user: User) => (
    <UserPhoneInput userId={user.id} phoneNumber={user.individualPhoneNumber} />
  );

  const formattedUsers: TableRowProps[] = users
    .filter((user) => user.firstName !== '' || user.lastName !== '')
    .map((user) => {
      const tableRow: TableRowProps = {
        key: user.id.toString(),
        row: [
          {
            accesor: 'fullName',
            value: `${user.firstName} ${user.lastName}`,
          },
          {
            accesor: 'phoneNumber',
            value: user.individualPhoneNumber,
            element: userPhoneNumberElement(user),
          },
        ],
      };

      return tableRow;
    });

  const filterOptions = [
    {
      label: 'Todos',
      filters: [],
    },
  ];

  if (usersLoading) {
    return <MessageLoading />;
  }

  if (usersError) {
    return <InfoError error={usersError} />;
  }

  return (
    // TODO: check when implementing i18n
    <ViewContainer title="Usuarios">
      <AdvancedTable
        columns={columns}
        data={formattedUsers}
        filterOptions={filterOptions}
      />
    </ViewContainer>
  );
};

export default UsersView;
