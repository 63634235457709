/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ToolCall } from './ToolCall';

export type OpenAIMessage = {
    tool_call_id?: string;
    tool_calls?: Array<ToolCall>;
    role: OpenAIMessage.role;
    content?: string | null;
};

export namespace OpenAIMessage {

    export enum role {
        ASSISTANT = 'assistant',
        FUNCTION = 'function',
        USER = 'user',
        SYSTEM = 'system',
        TOOL = 'tool',
    }


}

