import { Spinner } from '@material-tailwind/react';

const Loading: React.FC = () => {
  return (
    <div className="flex w-full justify-center">
      <Spinner color="blue" />
    </div>
  );
};

export default Loading;
