// src/components/molecules/analysis/summary-modal-content/summary-modal-content.tsx

import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import { AnalysisValueView } from 'src/api/generated';
import { getLatestContactQualiScore } from 'src/utils/analysisValues/scores/contactQuali';
import { getExtractions } from 'src/utils/analysisValues/extractions/extractions';
import { useMemo } from 'react';
import QualiScoreRow from './quali-score-row';
import ExtractionRow from './extraction-row';
import { getPublicScores } from 'src/utils/analysisValues/scores/scores';
import ScoreRow from './score-row';
import { splitIntoChunks } from 'src/utils/analysisValues/analysisValues';
import MessageLoading from 'components/atoms/loading/message-loading';
import { useAnalysisValueViews } from 'hooks/api/analysis-values/analysis-value-views';
import { FilterParam, generateFilterQueryString } from 'src/utils/queryParams';
import TextModalContent from '../text-modal-content';
import ForbiddenWordsRow from './forbidden-words-row';
import { getForbiddenWords } from 'src/utils/analysisValues/forbiddenWords/forbiddenWords';

interface SummaryModalContentProps {
  callId: number;
  enabled?: boolean;
}

// TODO: check when implementing i18n
const DEFAULT_MESSAGE = `El resumen de la llamada puede tardar hasta 1 minuto en aparecer. \
Solamente las llamadas efectivas se resumen.`;

const SCORES_PER_ROW = 2;

const SummaryModalContent: React.FC<SummaryModalContentProps> = ({
  callId,
  enabled = true,
}) => {
  const filters: Record<string, FilterParam> = {
    callId: { value: callId },
  };
  const filterBy = generateFilterQueryString(filters);
  const { analysisValueViews: analysisValues, loading } = useAnalysisValueViews(
    { filterBy },
    enabled
  );

  const extractions = useMemo(
    () => getExtractions(analysisValues),
    [analysisValues]
  );
  const latestContactQualiScore = useMemo(
    () => getLatestContactQualiScore(analysisValues),
    [analysisValues]
  );
  const chunkedPublicScores: AnalysisValueView[][] = useMemo(() => {
    const publicScores = getPublicScores(analysisValues);

    return splitIntoChunks(publicScores, SCORES_PER_ROW);
  }, [analysisValues]);
  const forbiddenWords = useMemo(
    () => getForbiddenWords(analysisValues),
    [analysisValues]
  );

  const qualiScoreRowComponent = latestContactQualiScore ? (
    <QualiScoreRow analysisValue={latestContactQualiScore} />
  ) : null;
  const extractionRowsComponent = extractions.map((extraction) => (
    <ExtractionRow key={extraction.id} extraction={extraction} />
  ));
  const forbiddenWordsRowComponent = forbiddenWords.length > 0 && (
    <ForbiddenWordsRow forbiddenWords={forbiddenWords} />
  );

  const scoreRowsComponent = chunkedPublicScores.map((scores, index) => (
    <ScoreRow key={index} scores={scores} />
  ));

  if (loading) {
    return <MessageLoading />;
  }
  if (
    !latestContactQualiScore &&
    !extractions.length &&
    !forbiddenWords.length &&
    !chunkedPublicScores.length
  ) {
    return <TextModalContent content={DEFAULT_MESSAGE} />;
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableBody>
          {qualiScoreRowComponent}
          {extractionRowsComponent}
          {forbiddenWordsRowComponent}
        </TableBody>
      </Table>
      <Divider className="py-2" />
      <Table size="small">
        <TableBody>{scoreRowsComponent}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default SummaryModalContent;
