import React from 'react';
import { useAlert } from 'src/contexts/alert-context';
import { useUpdateUser } from 'hooks/api/users';
import { isValidPhoneNumber } from 'src/utils/validation/phone';
import EditableTextInput from '../../shared/editable-text-input';

interface UserPhoneInputProps {
  userId: number;
  phoneNumber: string | null;
}

const UserPhoneInput: React.FC<UserPhoneInputProps> = ({
  userId,
  phoneNumber,
}) => {
  const { showAlert } = useAlert();

  // TODO: check when implementing i18n
  const handleUserUpdateSuccess = () => {
    showAlert('El número se ha guardado correctamente.', 'success');
  };
  const handleUserUpdateError = () => {
    showAlert(
      `Ocurrió un error actualizando el número. Por favor revisa tu conexión e intenta nuevamente.`,
      'error'
    );
  };
  const { updateUser, loading } = useUpdateUser(
    handleUserUpdateSuccess,
    handleUserUpdateError
  );

  const handlePhoneNumberSave = async (
    newPhoneNumber: string
  ): Promise<boolean> => {
    if (!isValidPhoneNumber(newPhoneNumber)) {
      // TODO: check when implementing i18n
      showAlert(
        'El número de teléfono no es válido. Asegúrate de que el formato sea +XXXXXXXXXXX.',
        'error'
      );
      return false;
    }
    updateUser({
      userId,
      updatedUser: {
        individualPhoneNumber: newPhoneNumber,
      },
    });
    return true;
  };

  return (
    <EditableTextInput
      content={phoneNumber}
      onSave={async (newPhoneNumber: string): Promise<boolean> =>
        await handlePhoneNumberSave(newPhoneNumber)
      }
      loading={loading}
    />
  );
};

export default UserPhoneInput;
