import React, { useState } from 'react';
import InlineEditButton from 'components/atoms/buttons/inline-edit-button';
import InlineTextInput from 'components/atoms/forms/inline-text-input';
import { Spinner } from '@material-tailwind/react';
import InlineSaveButton from 'components/atoms/buttons/inline-save-button';
import InlineCancelButton from 'components/atoms/buttons/inline-cancel-button';
import { FullName } from 'src/types/users';
import { formatFullName } from 'src/utils/formatting/name';

interface FullNameInputProps extends FullName {
  onSave: (newValues: FullName) => Promise<boolean>;
  loading?: boolean;
}

const FullNameInput: React.FC<FullNameInputProps> = ({
  firstName,
  lastName,
  onSave,
  loading,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [inputValues, setInputValues] = useState<FullName>({
    firstName,
    lastName,
  });
  const [originalValues] = useState<FullName>({ firstName, lastName });

  const handleSave = async () => {
    const success: boolean = await onSave(inputValues);
    if (success) {
      setEditMode(false);
    }
  };

  const handleCancel = () => {
    setInputValues(originalValues);
    setEditMode(false);
  };

  const handleChange = (field: string, newValue: string) => {
    setInputValues((prevValues: FullName) => ({
      ...prevValues,
      [field]: newValue,
    }));
  };

  const fullName = formatFullName(firstName, lastName);

  return (
    <div className="flex items-center space-x-1 justify-between text-left">
      {editMode ? (
        <>
          <InlineTextInput
            value={inputValues.firstName || ''}
            onChange={(newValue) => handleChange('firstName', newValue)}
            // TODO: check when implementing i18n
            placeholder="Nombre"
            className="h-7"
            containerClassName="w-32 min-w-0"
          />
          <InlineTextInput
            value={inputValues.lastName || ''}
            onChange={(newValue) => handleChange('lastName', newValue)}
            // TODO: check when implementing i18n
            placeholder="Apellido"
            className="h-7"
            containerClassName="w-32 min-w-0"
          />
          {loading ? (
            <Spinner className="h-4 w-4" />
          ) : (
            <div className="flex">
              <InlineSaveButton onClick={handleSave} />
              <InlineCancelButton onClick={handleCancel} />
            </div>
          )}
        </>
      ) : (
        <>
          <div>{fullName}</div>
          <InlineEditButton
            onClick={() => setEditMode(true)}
            className="w-7 h-7"
          />
        </>
      )}
    </div>
  );
};

export default FullNameInput;
