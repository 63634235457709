import React from 'react';
import { IconButton } from '@material-tailwind/react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

interface InlineEditButtonProps {
  onClick: () => void;
  className?: string;
  iconClassName?: string;
}

const InlineEditButton: React.FC<InlineEditButtonProps> = ({
  onClick,
  className = '',
  iconClassName = '',
}) => {
  return (
    <IconButton
      onClick={onClick}
      variant="text"
      className={`w-7 h-7 ${className}`}
    >
      <PencilSquareIcon
        strokeWidth={2}
        className={`h-4 w-4 text-blue-600 ${iconClassName}`}
      />
    </IconButton>
  );
};

export default InlineEditButton;
