import Sidebar from 'components/molecules/sidebar';
import { Outlet } from '@tanstack/react-router';
import DrawerPhone from 'components/organisms/phones/drawer-phone';
import { useState } from 'react';
import { useUserPhoneNumber } from 'hooks/phones/phones';
import InfoError from 'components/atoms/info-error';
import MessageLoading from 'components/atoms/loading/message-loading';
import Phone from 'components/organisms/phones/phone';
import { Typography } from '@material-tailwind/react';

function AuthenticatedApp() {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);
  const { phoneNumber, error, loading } = useUserPhoneNumber();

  const phoneComponent = () => {
    if (error) {
      return <InfoError error={error} />;
    }
    if (loading) {
      return <MessageLoading />;
    }
    return <Phone />;
  };
  const headerComponent = () => {
    return (
      <>
        {/* TODO: check when implementing i18n */}
        <Typography className="text-blue-50 text-xs">Mi número</Typography>
        <Typography className="text-blue-50 text-sm uppercase">
          {phoneNumber}
        </Typography>
      </>
    );
  };

  return (
    <main className="w-full">
      <section className="flex h-screen">
        <aside className="flex-none w-50 h-full overflow-y-auto">
          <Sidebar />
        </aside>
        <div className="flex-1 min-w-0 overflow-auto">
          <Outlet />
        </div>
      </section>
      <DrawerPhone
        isOpen={isDrawerOpen}
        toggle={setIsDrawerOpen}
        phoneComponent={phoneComponent()}
        headerComponent={headerComponent()}
      />
    </main>
  );
}

export default AuthenticatedApp;
