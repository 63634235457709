import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import {
  PhoneArrowDownLeftIcon,
  PhoneArrowUpRightIcon,
} from '@heroicons/react/24/solid';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Typography,
} from '@material-tailwind/react';
import CallDuration from 'components/molecules/calls/call-duration';
import CallInfoModals from 'components/molecules/calls/call-info-modals';
import { useState } from 'react';
import { CallLog, CallWithAnalysisAndUser } from 'src/api/generated';
import { isCallCompleted, isIncomingCall } from 'src/utils/calls';
import { formatDate, formatTime } from 'src/utils/formatting/date';

interface ContactCallItemProps {
  call: CallWithAnalysisAndUser;
  callLog: CallLog | null;
}

const ContactCallItem: React.FC<ContactCallItemProps> = ({ call, callLog }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen((prevState) => !prevState);

  const headerClasses = `
    border-0 px-4 py-2 flex flex-row [&>*:last-child]:hidden
    hover:pointer hover:bg-gray-200
  `;
  const bodyClasses = `
    px-4 pb-1 pt-0 flex flex-row items-center justify-between
  `;

  const callAgent = call.user?.firstName || '';
  const callDate = call.startTime ? formatDate(call.startTime, 'DD-MM') : '-';
  const callTime = call.startTime ? formatTime(call.startTime) : '';
  const isIncoming = isIncomingCall(call);

  return (
    <Accordion open={open} className="border-b-2">
      <AccordionHeader onClick={toggleOpen} className={headerClasses}>
        <div className="flex flex-row gap-x-5">
          {isIncoming ? (
            <div className="flex flex-row gap-x-1 text-green-600">
              <PhoneArrowDownLeftIcon strokeWidth={2} className="h-4 w-4" />
            </div>
          ) : (
            <div className="flex flex-row gap-x-1">
              <PhoneArrowUpRightIcon strokeWidth={2} className="h-4 w-4" />
            </div>
          )}

          <CallDuration call={call} callLog={callLog} />
        </div>
        <Typography className="text-xs ml-10">{`${callDate} ${callTime}`}</Typography>
        <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
      </AccordionHeader>
      <AccordionBody className={bodyClasses}>
        {/* TODO: check when implementing i18n */}
        <Typography className="text-xs py-2">{`Agente: ${callAgent}`}</Typography>
        <CallInfoModals
          callId={call.id}
          callAgentScore={call.agentScore}
          visible={isCallCompleted(call)}
          buttonClassName="w-8 h-8 ml-0 mr-0"
          iconClassName="h-4 w-4"
          playerHeight={32}
          playerWidth={180}
        />
      </AccordionBody>
    </Accordion>
  );
};

export default ContactCallItem;
