// src/components/molecules/opportunities/notes-text-area/notes-text-area.tsx

import EditableTextArea from 'components/molecules/forms/editable/shared/editable-text-area';
import { useUpdateOpportunity } from 'hooks/api/opportunities';
import { ApiQueryParams, Opportunity } from 'src/api/generated';
import { useAlert } from 'src/contexts/alert-context';

interface NotesTextAreaProps {
  opportunity: Opportunity;
  opportunitiesQueryParams?: ApiQueryParams;
}

const NotesTextArea: React.FC<NotesTextAreaProps> = ({
  opportunity,
  opportunitiesQueryParams,
}) => {
  const { showAlert } = useAlert();
  // TODO: check when implementing i18n
  const handleUserUpdateSuccess = () => {
    showAlert('Las observaciones se han actualizado correctamente.', 'success');
  };
  const handleUserUpdateError = () => {
    showAlert(
      `Ocurrió un error actualizando las observaciones. \
      Por favor revisa tu conexión e intenta nuevamente.`,
      'error'
    );
  };
  const cacheKey = opportunitiesQueryParams ? [opportunitiesQueryParams] : [];
  const { updateOpportunity } = useUpdateOpportunity(
    handleUserUpdateSuccess,
    handleUserUpdateError,
    cacheKey
  );

  const handleTextAreaSave = async (newValue: string): Promise<boolean> => {
    const updatedOpportunity: Partial<Opportunity> = {
      notes: newValue,
    };
    updateOpportunity({
      opportunityId: opportunity.id,
      updatedOpportunity,
    });
    return true;
  };

  return (
    <EditableTextArea
      content={opportunity.notes || ''}
      onSave={handleTextAreaSave}
    />
  );
};

export default NotesTextArea;
