/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SettingName {
    BYOC_TRUNK = 'byoc-trunk',
    CALL_WITHOUT_INTERNET = 'call-without-internet',
    CALL_WITHOUT_INTERNET_CHOOSE = 'call-without-internet-choose',
    CALL_QUALITY = 'call-quality',
    COMPANY_PHONES_FALLBACK = 'company-phones-fallback',
    CAN_RECEIVE_CALLS = 'can-receive-calls',
    COUNTRY = 'country',
    CRM_PUBLISH_FEEDBACK = 'crm-publish-feedback',
    DOWNLOADS_CALLS = 'downloads-calls',
    FEATURE_FLAG_CRM = 'feature-flag-crm',
    FEATURE_FLAG_SHOW_RECORDINGS = 'feature-flag-show-recordings',
    FEATURE_FLAG_SHOW_TRANSCRIPTIONS = 'feature-flag-show-transcriptions',
    FEATURE_FLAG_AGENT_SHOW_ALL_CALLS = 'feature-flag-agent-show-all-calls',
    FEATURE_FLAG_ADMIN_DOWNLOAD_RECORDINGS = 'feature-flag-admin-download-recordings',
    SHOW_REPORTS = 'show-reports',
    TIMEZONE = 'timezone',
    TWILIO_EDGE_LOCATIONS = 'twilio-edge-locations',
}
