import React from 'react';
import { Typography } from '@material-tailwind/react';
import { CallLog, CallWithAnalysisAndUser } from 'src/api/generated';
import {
  getDurationText,
  getFailedStatusMessage,
  getStatusClass,
} from 'src/utils/calls';
import TextTooltip from 'components/atoms/tooltips/text-tooltip';

interface CallDurationProps {
  call: CallWithAnalysisAndUser;
  callLog: CallLog | null;
}

const CallDuration: React.FC<CallDurationProps> = ({ call, callLog }) => {
  const isFailedCall = call.status === 'failed';
  const duration = getDurationText(call.status, call.duration);
  const statusClass = getStatusClass(call.status);
  const failedMessage = getFailedStatusMessage(call.status, callLog);

  return (
    <>
      {isFailedCall ? (
        <TextTooltip
          tooltipContent={failedMessage}
          placement="top"
          content={duration}
          iconClassName={statusClass}
        />
      ) : (
        <Typography className={`text-sm ${statusClass}`}>{duration}</Typography>
      )}
    </>
  );
};

export default CallDuration;
