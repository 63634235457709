import React from 'react';
import FullNameInput from '../../shared/full-name-input';
import { useUpdateContact } from 'hooks/api/contacts/contacts';
import { useAlert } from 'src/contexts/alert-context';
import { FullName } from 'src/types/users';
import { ApiQueryParams } from 'src/api/generated';

interface ContactFullNameInputProps extends FullName {
  contactId: number;
  contactsQueryParams?: ApiQueryParams;
}

const ContactFullNameInput: React.FC<ContactFullNameInputProps> = ({
  contactId,
  firstName,
  lastName,
  contactsQueryParams,
}) => {
  const { showAlert } = useAlert();

  // TODO: check when implementing i18n
  const handleContactUpdateSuccess = () => {
    showAlert('El nombre ha sido guardado correctamente.', 'success');
  };
  const handleContactUpdateError = () => {
    showAlert(
      `Ocurrió un error actualizando el nombre. Por favor revisa tu conexión e intenta nuevamente.`,
      'error'
    );
  };
  const cacheKey = contactsQueryParams ? [contactsQueryParams] : [];
  const { updateContact, loading } = useUpdateContact(
    handleContactUpdateSuccess,
    handleContactUpdateError,
    cacheKey
  );
  const handleContactFullNameUpdate = async (
    newValues: FullName
  ): Promise<boolean> => {
    updateContact({
      contactId,
      updatedContact: {
        firstName: newValues.firstName,
        lastName: newValues.lastName,
      },
    });
    return true;
  };

  return (
    <FullNameInput
      onSave={async (newValues: FullName): Promise<boolean> =>
        await handleContactFullNameUpdate(newValues)
      }
      loading={loading}
      firstName={firstName}
      lastName={lastName}
    />
  );
};

export default ContactFullNameInput;
