import { Spinner, Typography } from '@material-tailwind/react';

interface MessageLoadingProps {
  message?: string;
}

const MessageLoading: React.FC<MessageLoadingProps> = ({
  // TODO: check when implementing i18n
  message = 'Cargando',
}) => {
  return (
    <div className="flex flex-row gap-4 items-center justify-center min-w-[300px] p-6">
      <Typography className="text-xl text-blue-400">{message}</Typography>
      <Spinner color="blue" />
    </div>
  );
};

export default MessageLoading;
