/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Call } from '../models/Call';
import type { CallLog } from '../models/CallLog';
import type { CallSync } from '../models/CallSync';
import type { DailyCallSummary } from '../models/DailyCallSummary';
import type { NewAgentCall } from '../models/NewAgentCall';
import type { NewBridgeCall } from '../models/NewBridgeCall';
import type { PaginatedData_CallLog_ } from '../models/PaginatedData_CallLog_';
import type { PaginatedData_CallWithAnalysisAndUser_ } from '../models/PaginatedData_CallWithAnalysisAndUser_';
import type { SignedRecording } from '../models/SignedRecording';
import type { Transcription } from '../models/Transcription';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CallsService {

    /**
     * @param filterBy Filter criteria in the format "field:value;field2<value2;field3:[value3,value4,value5]" for exact matches.
     * @param searchBy Search criteria in the format "name lastname" for partial matches.
     * @param sortBy Sorting criteria in the format "field:asc;field2:desc".
     * @param page The page number in pagination.
     * @param pageSize The number of items per page in pagination.
     * @returns PaginatedData_CallLog_ Ok
     * @throws ApiError
     */
    public static getCallLogs(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedData_CallLog_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/calls/logs',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param filterBy Filter criteria in the format "field:value;field2<value2;field3:[value3,value4,value5]" for exact matches.
     * @param searchBy Search criteria in the format "name lastname" for partial matches.
     * @param sortBy Sorting criteria in the format "field:asc;field2:desc".
     * @param page The page number in pagination.
     * @param pageSize The number of items per page in pagination.
     * @returns PaginatedData_CallWithAnalysisAndUser_ Ok
     * @throws ApiError
     */
    public static getCalls(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedData_CallWithAnalysisAndUser_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/calls',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * Retrieves featured calls.
     * Optionally filters the summaries by a date range specified through query parameters.
     * If no date range is provided, it defaults to the current day.
     *
     * Date parameters should be in ISO 8601 date-time format (YYYY-MM-DDTHH:MM:SSZ).
     * @param startDate - The optional start date for the summary period (inclusive).
     * @param endDate - The optional end date for the summary period (inclusive).
     * @param filterBy
     * @param pageSize
     * @returns Call Ok
     * @throws ApiError
     */
    public static getFeaturedCalls(
        startDate?: string,
        endDate?: string,
        filterBy?: string,
        pageSize?: number,
    ): CancelablePromise<Array<Call>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/calls/featured',
            query: {
                'startDate': startDate,
                'endDate': endDate,
                'filterBy': filterBy,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param callId
     * @returns CallLog Ok
     * @throws ApiError
     */
    public static getCallLog(
        callId: number,
    ): CancelablePromise<CallLog> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/calls/{callId}/Logs',
            path: {
                'callId': callId,
            },
            errors: {
                401: `Unauthorized.`,
                404: `Not found.`,
            },
        });
    }

    /**
     * @deprecated
     * @param callId
     * @returns SignedRecording Ok
     * @throws ApiError
     */
    public static getCallSignedRecording(
        callId: number,
    ): CancelablePromise<SignedRecording> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/calls/{callId}/recordings/signed',
            path: {
                'callId': callId,
            },
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param callId
     * @returns Transcription Ok
     * @throws ApiError
     */
    public static getCallTranscription(
        callId: number,
    ): CancelablePromise<Transcription> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/calls/{callId}/transcriptions',
            path: {
                'callId': callId,
            },
            errors: {
                401: `Unauthorized.`,
                404: `Not found.`,
            },
        });
    }

    /**
     * @deprecated
     * @param requestBody
     * @returns Call Ok
     * @throws ApiError
     */
    public static startBridgeCall(
        requestBody: NewBridgeCall,
    ): CancelablePromise<Call> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/calls/start/bridge',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Call Ok
     * @throws ApiError
     */
    public static startAgentCall(
        requestBody: NewAgentCall,
    ): CancelablePromise<Call> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/calls/start/agent',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * Retrieves call summaries for a given tenant.
     * Optionally filters the summaries by a date range specified through query parameters.
     * If no date range is provided, it defaults to the last 7 days.
     *
     * Date parameters should be in ISO 8601 date-time format (YYYY-MM-DDTHH:MM:SSZ).
     * @param startDate - The optional start date for the summary period (inclusive).
     * @param endDate - The optional end date for the summary period (inclusive).
     * @returns DailyCallSummary Ok
     * @throws ApiError
     */
    public static getCallSummaries(
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<Array<DailyCallSummary>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/summaries/calls',
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
            errors: {
                400: `Bad Request.`,
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static triggerCallSync(
        requestBody: CallSync,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/syncs/calls',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

}
