import {
  ApiQueryParams,
  CallLog,
  CallWithAnalysisAndUser,
  Company,
  Contact,
  DownloadType,
  User,
} from 'src/api/generated';
import ViewContainer from 'components/atoms/view-container';
import Table, { TableRowProps } from 'components/molecules/tables/table';
import { Chip, Typography } from '@material-tailwind/react';
import PhoneNumberItem from 'components/molecules/phone/phone-number-item';
import Finder from 'components/molecules/finder';
import ViewFilter from 'components/atoms/view-filter';
import AgentSelect from 'components/molecules/agent-select';
import { useAgents } from 'hooks/api/users';
import {
  useCallsCallLogs,
  useCallsContacts,
  useCallsQueryParams,
} from 'hooks/calls';
import { useCurrentUser } from 'hooks/users';
import { formatDate, formatTime } from 'src/utils/formatting/date';
import Paginator from 'components/molecules/tables/paginator';
import SortingHeader from 'components/molecules/tables/sorting-header';
import CallDirectionDisplay from 'components/molecules/calls/call-direction-display';
import {
  CallDirection,
  findCallLog,
  findContact,
  getCategoryColor,
  getCategoryText,
  getDurationText,
  isCallCompleted,
  isIncomingCall,
} from 'src/utils/calls';
import { formatFullName, formatName } from 'src/utils/formatting/name';
import { useCalls } from 'hooks/api/calls/calls';
import CallInfoModals from 'components/molecules/calls/call-info-modals';
import { useCurrentCompany } from 'hooks/api/companies';
import DownloadButton from 'components/molecules/download-button';
import CallDuration from 'components/molecules/calls/call-duration';
import ContactFullNameInput from 'components/molecules/forms/editable/contacts/contact-full-name-input';
import Loading from 'components/atoms/loading/loading';

const DEFAULT_SORT_FIELD = 'startTime';
const DEFAULT_SORT_ORDER = 'desc';
const DEFAULT_PAGE_SIZE = 10;

const CallHistoryView: React.FC = () => {
  const { users, loading: usersLoading, error: usersError } = useAgents();
  const { currentUser } = useCurrentUser();
  const { currentCompany } = useCurrentCompany();

  const {
    queryParams,
    selectedAgentId,
    sendAgentSelection,
    sendSearch,
    sortField,
    selectSortField,
    sortOrder,
    setSortOrder,
    page,
    setPage,
  } = useCallsQueryParams(
    users,
    currentUser,
    DEFAULT_SORT_FIELD,
    DEFAULT_SORT_ORDER,
    DEFAULT_PAGE_SIZE
  );
  const {
    calls,
    totalPages,
    loading: callsLoading,
    error: callsError,
  } = useCalls(queryParams);
  const { callLogs } = useCallsCallLogs(calls);
  const {
    contacts,
    queryParams: contactsQueryParams,
    loading: contactsLoading,
  } = useCallsContacts(calls);

  const rows: TableRowProps[] = calls.map((call) =>
    buildRow(
      call,
      callLogs,
      contacts,
      currentUser,
      currentCompany,
      contactsQueryParams,
      contactsLoading
    )
  );

  const loading = callsLoading || usersLoading;
  const error = callsError || usersError;

  // TODO: check when implementing i18n
  const columns = [
    {
      header: 'Dirección',
      accessor: 'direction',
      className: 'py-0.5',
    },
    {
      header: 'Número',
      accessor: 'to',
      className: 'py-0.5',
    },
    {
      header: 'Nombre',
      accessor: 'contactId',
      className: 'py-0.5 min-w-[200px]',
    },
    {
      header: 'Fecha',
      accessor: 'startTime',
      className: 'py-0.5',
    },
    {
      header: 'Duración',
      accessor: 'duration',
      className: 'py-0.5',
    },
    {
      header: 'Categoría',
      accessor: 'category',
      className: 'py-0.5',
    },
    {
      header: 'Agente',
      accessor: 'userId',
      className: 'py-0.5',
    },
    {
      header: 'Resumen',
      accessor: 'summary',
      disabled: true,
      className: 'py-0.5',
    },
  ];

  const headerElement = (
    <ViewFilter>
      <DownloadButton downloadType={DownloadType.CALLS} />
      <AgentSelect
        agents={users}
        selectedAgent={selectedAgentId}
        onAgentSelectionChange={sendAgentSelection}
      />
      <Finder onSearch={sendSearch} />
    </ViewFilter>
  );

  const sortableColumns = columns.map((column) => ({
    ...column,
    header: (
      <SortingHeader
        header={column.header}
        accessor={column.accessor}
        sortField={sortField}
        onSetSortField={selectSortField}
        order={sortOrder}
        onSetOrder={setSortOrder}
        disabled={column.disabled}
      />
    ),
  }));

  return (
    <ViewContainer
      // TODO: check when implementing i18n
      title="Historial de llamadas"
      headerElement={headerElement}
      loading={loading}
      error={error}
    >
      <Table columns={sortableColumns} data={rows} />
      <Paginator
        currentPage={page}
        totalPages={totalPages}
        onPageChange={setPage}
      />
    </ViewContainer>
  );
};

const buildRow = (
  call: CallWithAnalysisAndUser,
  callLogs: CallLog[],
  contacts: Contact[],
  user: User | null,
  company: Company | null,
  contactsQueryParams: ApiQueryParams,
  contactsLoading: boolean
): TableRowProps => {
  const callLog = findCallLog(call, callLogs);
  const contact = findContact(call, contacts);
  const contactName = formatFullName(contact?.firstName, contact?.lastName);
  const direction = call.direction as CallDirection;
  const redirectedTo = call.redirectedTo;
  const isIncoming = isIncomingCall(call);
  const contactPhone = isIncoming ? call.from : call.to;
  const startTime = call.startTime || 0;
  const duration = getDurationText(call.status, call.duration);
  const category = getCategoryText(call.category);
  const categoryColor = getCategoryColor(call.category);
  const agentName = formatFullName(call.user?.firstName, call.user?.lastName);

  const directionElement = () => (
    <CallDirectionDisplay direction={direction} redirectedTo={redirectedTo} />
  );
  const phoneElement = () => {
    return (
      <PhoneNumberItem
        phoneNumber={contactPhone}
        userName={formatName(user?.firstName)}
        companyName={formatName(company?.name)}
      />
    );
  };

  const contactNameElement = () => {
    if (contactsLoading) return <Loading />;
    if (!contact) return;

    return (
      <ContactFullNameInput
        contactId={contact.id}
        firstName={contact.firstName}
        lastName={contact.lastName}
        contactsQueryParams={contactsQueryParams}
      />
    );
  };
  const startTimeElement = () => {
    return (
      <div className="inline-block">
        <Typography className="text-sm leading-4 text-center whitespace-nowrap">
          {call.startTime ? formatDate(call.startTime, 'DD-MM-YYYY') : '-'}
        </Typography>
        <Typography className="text-sm leading-4 text-center whitespace-nowrap">
          {call.startTime && formatTime(call.startTime)}
        </Typography>
      </div>
    );
  };
  const durationElement = () => {
    return <CallDuration call={call} callLog={callLog} />;
  };
  const categoryElement = () => {
    if (!category) return;
    return (
      <Chip
        variant="ghost"
        color={categoryColor}
        size="sm"
        value={category}
        className="w-fit"
      />
    );
  };
  const summaryElement = () => (
    <CallInfoModals
      callId={call.id}
      callAgentScore={call.agentScore}
      visible={isCallCompleted(call)}
    />
  );

  const tableRow: TableRowProps = {
    key: call.id.toString(),
    row: [
      {
        accesor: 'direction',
        value: direction,
        element: directionElement(),
      },
      {
        // TODO: should be "to" or "from" depending on the call direction
        accesor: 'to',
        value: contactPhone,
        element: phoneElement(),
      },
      {
        accesor: 'contactId',
        value: contactName,
        element: contactNameElement(),
      },
      {
        accesor: 'startTime',
        value: startTime,
        element: startTimeElement(),
      },
      {
        accesor: 'duration',
        value: duration,
        element: durationElement(),
      },
      {
        accesor: 'category',
        value: null,
        element: categoryElement(),
      },
      {
        accesor: 'userId',
        value: agentName || '-',
      },
      {
        accesor: 'summary',
        value: null,
        element: summaryElement(),
      },
    ],
  };
  return tableRow;
};

export default CallHistoryView;
