/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Contact } from '../models/Contact';
import type { NewContact } from '../models/NewContact';
import type { PaginatedData_ContactView_ } from '../models/PaginatedData_ContactView_';
import type { PaginatedData_ContactWithCalls_ } from '../models/PaginatedData_ContactWithCalls_';
import type { Partial_Contact_ } from '../models/Partial_Contact_';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContactsService {

    /**
     * @param filterBy Filter criteria in the format "field:value;field2<value2;field3:[value3,value4,value5]" for exact matches.
     * @param searchBy Search criteria in the format "name lastname" for partial matches.
     * @param sortBy Sorting criteria in the format "field:asc;field2:desc".
     * @param page The page number in pagination.
     * @param pageSize The number of items per page in pagination.
     * @returns Contact Ok
     * @throws ApiError
     */
    public static getContacts(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<Array<Contact>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/contacts',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Contact Ok
     * @throws ApiError
     */
    public static createContact(
        requestBody: NewContact,
    ): CancelablePromise<Contact> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/contacts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param filterBy Filter criteria in the format "field:value;field2<value2;field3:[value3,value4,value5]" for exact matches.
     * @param searchBy Search criteria in the format "name lastname" for partial matches.
     * @param sortBy Sorting criteria in the format "field:asc;field2:desc".
     * @param page The page number in pagination.
     * @param pageSize The number of items per page in pagination.
     * @returns PaginatedData_ContactWithCalls_ Ok
     * @throws ApiError
     */
    public static getContactsWithCalls(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedData_ContactWithCalls_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/contacts/calls',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Contact Ok
     * @throws ApiError
     */
    public static updateContact(
        id: number,
        requestBody: Partial_Contact_,
    ): CancelablePromise<Contact> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/contacts/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized.`,
                404: `Resource not found.`,
            },
        });
    }

    /**
     * @param filterBy Filter criteria in the format "field:value;field2<value2;field3:[value3,value4,value5]" for exact matches.
     * @param searchBy Search criteria in the format "name lastname" for partial matches.
     * @param sortBy Sorting criteria in the format "field:asc;field2:desc".
     * @param page The page number in pagination.
     * @param pageSize The number of items per page in pagination.
     * @returns PaginatedData_ContactView_ Ok
     * @throws ApiError
     */
    public static getContactViews(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedData_ContactView_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/contacts/views',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param filterBy Filter criteria in the format "field:value;field2<value2;field3:[value3,value4,value5]" for exact matches.
     * @param searchBy Search criteria in the format "name lastname" for partial matches.
     * @param sortBy Sorting criteria in the format "field:asc;field2:desc".
     * @param page The page number in pagination.
     * @param pageSize The number of items per page in pagination.
     * @returns PaginatedData_ContactWithCalls_ Ok
     * @throws ApiError
     */
    public static getOpportunitiesContacts(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedData_ContactWithCalls_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/opportunities/contacts/calls',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

}
