// src/components/molecules/analysis/summary-modal-content/summary-modal-content.tsx

import MessageLoading from 'components/atoms/loading/message-loading';
import { useCallTranscription } from 'hooks/api/calls/scoped/call-transcriptions';
import TextModalContent from '../text-modal-content';

interface TranscriptionModalContentProps {
  callId: number;
  enabled?: boolean;
}

// TODO: check when implementing i18n
const DEFAULT_MESSAGE = `La transcripción puede tardar hasta 30 \
  segundos en aparecer, intentar refrescar el historial. Las llamadas perdidas \
  no generan transcripción.\
`;

const TranscriptionModalContent: React.FC<TranscriptionModalContentProps> = ({
  callId,
  enabled = true,
}) => {
  const { transcription, loading } = useCallTranscription(callId, enabled);

  if (loading) {
    return <MessageLoading />;
  }

  if (!transcription?.text) {
    return <TextModalContent content={DEFAULT_MESSAGE} />;
  }

  return <TextModalContent content={transcription.text} />;
};

export default TranscriptionModalContent;
